<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.gideonProductDetailPageInfo.label_page_title }}</h1>
      <BannerAid :bannerLink="`product-details|${gideonStoreSelectedProductId}|${gideonStoreSelectedProductKey}`" />
    </header>
    <b-container class="top">
      <b-row class="top-row">
        <b-col sm="12" xs="12" class="top-col mb-5">
          <top-nav-search
            class="top-nav-search"
            :showDescriptionText="showDescriptionText"
            :backRouteName="`store-category-products`"
            :backLabel="gideonProductDetailPageInfo.Subcategory.category_name"
            :showMerchTotal="showMerchTotal"
            :i18n="translations.components"
          >
          </top-nav-search>
          <camp-selector
            @camp_changed="page_load($event)"
            :onlyUSA="onlyUSA"
            :tooltip="translations.tcCurrentHome"
            class="mt-3 mb-1"
            v-if="!showMerchTotal"
            :i18n="translations.components"
          ></camp-selector>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-title mb-5 row">
          <div class="title col-lg-4 col-md-4 col-sm-12">
            <h4>{{ this.gideonProductDetailPageInfo.name }}</h4>
          </div>
          <div class="category-ddl col-lg-4 col-md-4 col-sm-12">
            <b-form-select
              id="product-select"
              name="product-select"
              title="Product"
              v-model="selectedProduct"
              :options="productOptions"
            ></b-form-select>
            <b-button @click.stop="handleChangeProduct" variant="primary" class="ml-2 btn-go">
              {{ this.gideonProductDetailPageInfo.button_Go }}
            </b-button>
          </div>
        </b-col>
        <b-col sm="12" xs="12" class="tile-col-details mb-5">
          <b-row class="tile-row" sm="12" xs="12">
            <b-col class="container-image">
              <div id="MainImageDiv" class="MainImageDiv mb-3">
                <img :src="selectedImagePath" alt="" class="product-image" />
              </div>
              <div id="ThumbnailsDiv" class="ThumbnailsDiv">
                <img
                  v-if="this.gideonProductDetailPageInfo.Product.prd_image_file_path"
                  :src="this.gideonProductDetailPageInfo.Product.prd_image_file_path"
                  alt="thumbnail"
                  class="thumb-image"
                  @click="setImage(gideonProductDetailPageInfo.Product.prd_image_file_path)"
                />
                <img
                  v-if="this.gideonProductDetailPageInfo.Product.prd_detail_image_file_path1"
                  :src="this.gideonProductDetailPageInfo.Product.prd_detail_image_file_path1"
                  alt="thumbnail"
                  class="thumb-image"
                  @click="setImage(gideonProductDetailPageInfo.Product.prd_detail_image_file_path1)"
                />
                <img
                  v-if="this.gideonProductDetailPageInfo.Product.prd_detail_image_file_path2"
                  :src="this.gideonProductDetailPageInfo.Product.prd_detail_image_file_path2"
                  alt="thumbnail"
                  class="thumb-image"
                  @click="setImage(gideonProductDetailPageInfo.Product.prd_detail_image_file_path2)"
                />
              </div>
            </b-col>
            <b-col class="container-info" sm="6" xs="12">
              <div class="product-detail">
                <div class="prd-name">{{ this.gideonProductDetailPageInfo.Product.prd_name }}</div>
                <div class="prd-code">({{ this.gideonProductDetailPageInfo.Product.prd_code }})</div>
                <div class="prd-price mb-5">
                  {{ this.gideonProductDetailPageInfo.label_price }} ${{
                    this.gideonProductDetailPageInfo.Product.prd_display_price
                  }}
                </div>
                <div class="prd-description" v-html="this.gideonProductDetailPageInfo.Product.prd_store_description" />
              </div>
              <div class="container-cart-buttons mt-5">
                <span class="quanity-label mr-2">{{ this.gideonProductDetailPageInfo.label_quantity }}</span>
                <b-form-input
                  class="product-quanity mr-2"
                  title="Product-Quanity"
                  id="Product-Quanity"
                  v-model="productQuantity"
                  required
                  min="1"
                  type="number"
                ></b-form-input>
                <b-button
                  variant="primary"
                  size="sm"
                  class="btn btn-primary cart-buttons mr-2"
                  @click="addProductToCart(gideonProductDetailPageInfo.Product.prd_key, productQuantity)"
                  >{{ this.gideonProductDetailPageInfo.button_AddToCart }}</b-button
                >
                <b-button
                  variant="secondary"
                  size="sm"
                  class="btn btn-secondary cart-buttons cart-button-favorite"
                  @click="handleAddToFavorites()"
                  >{{ this.gideonProductDetailPageInfo.button_AddToFavorites }}</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-title mb-5">
          <div class="title">
            <h4>{{ this.gideonProductDetailPageInfo.label_OtherProduct_Title }}</h4>
          </div>
        </b-col>
        <b-col
          sm="4"
          xs="12"
          class="tile-col mb-5"
          v-for="(obj, index) in gideonProductDetailPageInfo.OtherProducts"
          :key="index"
        >
          <card
            :itemId="obj.ItemID"
            :prd_key="obj.prd_key"
            :prd_code="obj.prd_code"
            :prd_image_file_path="obj.prd_image_file_path"
            :prd_display_price="obj.prd_display_price"
            :prd_name="obj.prd_name"
            :prd_show_out_of_stock="obj.prd_show_out_of_stock === 'True' ? true : false"
            :label_product_not_available="gideonProductDetailPageInfo.label_product_not_available"
            :btnAddToCart="gideonProductDetailPageInfo.button_AddToCart"
            :labelPrice="gideonProductDetailPageInfo.label_price"
            :labelItem="gideonProductDetailPageInfo.label_item"
            @set_item="addProductToCart($event.prd_key, $event.quantity)"
            @set_product="setProductAndRedirect($event)"
          >
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import CampSelect from '@/components/CampSelect.vue'
import Card from './CardProductList.vue'
import TopNavSearch from './TopNavSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'store-product-details',
  mixins: [translationMixin],
  data() {
    return {
      storeLPPayload: {
        lang: 'en', // default to english for now
        prd_key: '',
        landingpage: 'Store_ProductDetail',
      },
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      showDescriptionText: false,
      selectedProduct: null,
      productQuantity: 1,
      selectedImagePath: '',
      onlyUSA: true,
      translations: {},
    }
  },
  components: {
    BannerAid: BannerAid,
    campSelector: CampSelect,
    card: Card,
    topNavSearch: TopNavSearch,
  },
  methods: {
    ...mapActions({
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      loadStoreProductDetailPage: 'gideonStore/loadStoreProductDetailPage',
      setLoadingStatus: 'menu/setLoadingStatus',
      setStoreSelectedProductId: 'gideonStore/setStoreSelectedProductId',
      setStoreSelectedProductKey: 'gideonStore/setStoreSelectedProductKey',
      setStoreSelectedSubCategoryId: 'gideonStore/setStoreSelectedSubCategoryId',
      updateStoreCart: 'gideonStore/updateStoreCart',
      updateStoreFavorites: 'gideonStore/updateStoreFavorites',
    }),
    async page_load(evt) {
      if (!evt) {
        try {
          this.setLoadingStatus(true)
          this.storeNavPagePayload.lang = this.prefCulture
          this.storeLPPayload.lang = this.prefCulture
          this.storeLPPayload.prd_key = this.gideonStoreSelectedProductKey
          this.selectedProduct = this.gideonStoreSelectedProductKey
          await Promise.all([
            await this.loadStoreProductDetailPage(this.storeLPPayload),
            await this.loadStoreNavigationPage(this.storeNavPagePayload),
            await this.setStoreSelectedSubCategoryId(this.gideonProductDetailPageInfo.Subcategory.itemId),
            this.getViewTranslations(),
            (this.selectedImagePath = this.gideonProductDetailPageInfo.Product.prd_image_file_path),
            (this.productQuantity = this.gideonProductDetailPageInfo.cart_quantity),
            this.getComponentTranslations('camp-select', 'store-topnav-search'),
          ]).then((results) => {
            const componentTranslations = results[6]
            this.$set(this.translations, 'components', componentTranslations)
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async handleChangeProduct() {
      this.setStoreSelectedProductKey(this.selectedProduct)
      await this.page_load()
    },
    async setProductAndRedirect(data) {
      this.setStoreSelectedProductKey(data.prd_key)
      await this.page_load()
    },
    async addProductToCart(prd_key, quantity) {
      let cartPayload = {
        prd_key: prd_key,
        quantity: quantity,
      }
      let response = false
      await Promise.all([
        //add in the call to add to cart
        (response = await this.updateStoreCart(cartPayload)),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAddingItem || 'There was an error adding the item to the cart.',
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
      this.$router.push({ name: 'store-cart' })
    },
    async handleAddToFavorites() {
      let favePayload = {
        prd_key: this.selectedProduct,
        isfavorite: 1,
      }
      let response = false
      await Promise.all([
        //add in the call to add to cart
        (response = await this.updateStoreFavorites(favePayload)),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAddingItemFavorites || 'There was an error adding the item to the favorites.',
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      } else {
        this.$swal({
          icon: 'success',
          text: this.translations.tcItemAddedToFavorites || 'The item was added to the favorites.',
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
    },
    setImage(imgsrc) {
      this.selectedImagePath = imgsrc
    },
  },
  computed: {
    ...mapGetters({
      categories: 'gideonStore/gideonStoreCategories',
      gideonProductDetailPageInfo: 'gideonStore/gideonProductDetailPageInfo',
      gideonStoreProducts: 'gideonStore/gideonStoreProducts',
      gideonStoreSelectedProductId: 'gideonStore/gideonStoreSelectedProductId',
      gideonStoreSelectedProductKey: 'gideonStore/gideonStoreSelectedProductKey',
      prefCulture: 'user/userPreferredCulture',
    }),
    productOptions() {
      let options = []
      if (this.gideonStoreProducts.length > 0) {
        options = this.gideonStoreProducts.map((x) => {
          return {
            text: x.prd_name,
            value: x.prd_key,
          }
        })
      }
      return options
    },
    showMerchTotal() {
      let smt = false
      if (this.categories[0].CategoryName === 'Merchandise') {
        smt = true
      }
      return smt
    },
  },
  async created() {
    const prdKey = this.$route.params.prd_key
    if (prdKey) {
      this.setStoreSelectedProductKey(prdKey)
      this.$router.replace('/resources/store/product-details')
    }
    await this.page_load()
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}
.product-quanity {
  width: 65px;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
}
.cart-buttons {
  padding: 8px;
  font-size: 12px;
  white-space: nowrap;
}
.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}
.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}
.switch-buttons {
  margin: 0 auto;
  text-align: center;
  .merchscriptBtn {
    background-color: #747474;
  }
  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}
.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  background-color: #ffffff;
  padding-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;
  .tile-col-title {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    .category-ddl {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: space-between;
      .btn-go {
        padding: 8px;
      }
    }
  }
  .tile-col-details {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    .tile-row {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      border: 0;
    }
    .container-image {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
      .MainImageDiv {
        text-align: center;
        .product-image {
          max-width: 300px;
          max-height: 300px;
          min-height: 300px;
        }
      }
      .ThumbnailsDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .thumb-image {
          max-height: 50px;
          min-height: 50px;
          border: 1px solid gray;
          margin: 3px 10px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .container-info {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-between;
      .product-detail {
        .prd-name {
          font-size: 24px;
          color: $primary-color;
          text-transform: uppercase;
        }
        .prd-code {
          font-size: 24px;
        }
        .prd-price {
          font-size: 24px;
          text-transform: uppercase;
        }
      }
      .container-cart-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: flex-start;
        justify-content: flex-start;
        .quanity-label {
          font-weight: bold;
        }
        .cart-button-favorite {
          background-color: #747474;
        }
      }
    }
  }
  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    .tile-col-card {
      // flex-grow: 2;
      // .icon {
      //   min-height: 75px;
      // }
    }
  }
}

.tiles {
  margin-bottom: 80px;
}
</style>
